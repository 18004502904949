import { Branch, BranchListingQueryParams, BranchListItem } from '~src/types';
import { axiosInstance } from '~src/helpers';

export const getBranches = async (
  queryParams: BranchListingQueryParams
): Promise<BranchListItem[]> => {
  const { data } = await axiosInstance.get('/branches', { params: queryParams });

  return data;
};

export const getBranchesCount = async (queryParams: BranchListingQueryParams): Promise<number> => {
  const { data } = await axiosInstance.get('/branches/count', { params: queryParams });
  return data;
};

export const getBranch = async (branchId: number): Promise<Branch> => {
  const { data } = await axiosInstance.get(`/branches/${branchId}`);
  return data;
};
