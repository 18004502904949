import {
  profileUpdateEvent,
  userLoginEvent,
  userRegisterEvent,
  profileUpdatePasswordEvent,
  userLogout
} from './user';
import {
  storeFilterByNameEvent,
  storeFilteringEvent,
  storeSearchEvent,
  storeSelectedEvent
} from './store';
import { productAddedEvent, productSearchByNameEvent } from './product';
import {
  cartViewEvent,
  checkoutInitEvent,
  paymentInfoAddedEvent,
  checkoutCompletedEvent
} from './checkout';

export const events: Record<string, Function> = {
  'user:login': userLoginEvent,
  'user:register': userRegisterEvent,
  'user:updateProfile': profileUpdateEvent,
  'user:updatePassword': profileUpdatePasswordEvent,
  'store:search:home': storeSearchEvent,
  'store:filter:name': storeFilterByNameEvent,
  'store:filter': storeFilteringEvent,
  'store:selected': storeSelectedEvent,
  'product:filter:name': productSearchByNameEvent,
  'product:added': productAddedEvent,
  'cart:view': cartViewEvent,
  'checkout:init': checkoutInitEvent,
  'payment:card:added': paymentInfoAddedEvent,
  'checkout:completed': checkoutCompletedEvent,
  'user:logout': userLogout
};
