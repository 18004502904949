import { getUserContext } from '~src/api';
import { EventParam, EventType, ParamFormData, ProductAdded } from '~src/types';
import { getCommonEventPayload } from '~src/events/common';
import { pushEvent } from '~src/helpers';

export const productSearchByNameEvent = async (params: EventParam) => {
  const userContext = await getUserContext();
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.PRODUCT_FILTERING_BY_NAME, {
    ...commonEventPayload,
    Store_ID: userContext?.branch?.id,
    Store_Name: userContext?.branch?.name,
    Search_Keyword: params.search,
    Count_Of_Products: params.count,
    User_Logged_In: !!userContext?.customer
  });
};

export const productAddedEvent = async (params: EventParam) => {
  const formData = params.formData as ParamFormData;
  const orderItem = (params.response as ProductAdded)?.orderItem;
  const product = orderItem?.product;

  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.PRODUCT_ADDED, {
    ...commonEventPayload,
    Customisation_Available: Boolean(product?.hasOptionGroups),
    Category_Rank: String(
      formData?.find((formDataItem) => formDataItem?.name === 'categoryPosition')?.value || ''
    ),
    Product_Rank: String(product?.position),
    Category_Name: String(
      formData?.find((formDataItem) => formDataItem?.name === 'categoryName')?.value
    ),
    Product_ID: String(product?.id),
    Image_Displayed: String(product?.productPhotoUrl || '') || false,
    Price: Number(orderItem?.purchaseSubTotal),
    Description_Displayed: String(product?.description || '') || false,
    Product_Name: String(product?.name),
    Store_ID: Number(formData?.find((formDataItem) => formDataItem?.name === 'branchId')?.value),
    Store_Name: String(formData?.find((formDataItem) => formDataItem?.name === 'branchName')?.value)
  });
};
