export type Service = {
  id: number;
  orderType: string;
  orderTime: number;
  minsBeforeClose: number;
  active: boolean;
  servingNow: boolean;
  hasPreOrdering: boolean;
  orderForNow: boolean;
  hasPreOrderSettings: boolean;
  selfIsolationService: string;
  branchId: number;
};
