import { ProductAdded } from './productAdded';

export type ParamFormDataItem = {
  name: string;
  value: string | number | boolean;
};

export type ParamFormData = ParamFormDataItem[];

export type EventParam = Record<
  string,
  ParamFormData | string | number | null | undefined | ProductAdded
>;
