export type Product = {
  id?: number;
  code?: string;
  code1?: null;
  code2?: null;
  code3?: null;
  name?: string;
  name1?: string;
  name2?: null;
  name3?: null;
  description?: string;
  description1?: string;
  description2?: null;
  description3?: null;
  productPhotoUrl?: string;
  promotionCode?: null;
  brandName?: null;
  difficulty?: null;
  servings?: null;
  timeToCook?: null;
  price?: number;
  position?: number;
  active?: boolean;
  archive?: boolean;
  hasOptionGroups?: boolean;
  categoryId?: number;
  menuId?: number;
  optionGroups?: any[];
  productDayTimes?: any[];
  allergies?: any[];
  dietaries?: any[];
  productTags?: any[];
  productIngredients?: any[];
  productNutritions?: any[];
};
