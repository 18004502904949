export type AddressFieldRule = {
  id?: number;
  fieldName?: string;
  label?: string;
  display?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  userInterfaceClass?: string;
  userInterfaceType?: string;
  position?: number;
  parent?: number;
  companyId?: number;
};

export type Address = {
  id?: number;
  addressFields?: AddressField[];
  archive?: boolean;
  customerId?: number;
  isDefault?: boolean;
};

export type AddressField = {
  id?: number | null;
  fieldName?: string;
  label?: string;
  fieldValue?: string;
  addressId?: number;
  customerOrderAddressId?: number;
};
