import { PaymentMethodIntegration } from '~src/types/payment';
import { Locale } from './locale';
import { Website } from './website';
import { AddressFieldRule } from './address';
import { BusinessCategory } from './businessCategory';
import { Setting } from './setting';
import { Country } from './country';

export type CompanyIntegrationParam = {
  id?: number;
  dataType?: string;
  key?: string;
  value?: string;
  companyIntegrationId?: number;
};

export type CompanyIntegration = {
  id?: number;
  integrationType?: string;
  channel?: string;
  vendorName?: string;
  serviceRequestTrigger?: string;
  active?: boolean;
  companyId?: number;
  companyIntegrationParams?: CompanyIntegrationParam[];
};

export type CompanySetting = {
  id?: number;
  enableDriverTip?: boolean;
  enableOTPPhoneVerification?: boolean;
  enableGuestCheckout?: boolean;
  storeSignUpToEmail?: string;
  driverSignUpToEmail?: string;
  driverDeliveryChargeShareRate?: number;
  companyId?: number;
};

export type Company = {
  id?: number;
  name?: string;
  description?: string;
  locationWebLogoUrl?: string;
  companyType?: string;
  deliveryZoneType?: string;
  active?: boolean;
  archive?: boolean;
  clientSendPushNotification?: boolean;
  androidAppURL?: string;
  salesCompanyName?: string;
  salesCompanyWebsite?: string;
  registrationDate?: null;
  expiryDate?: null;
  tradingName?: null;
  registeredCountry?: null;
  addressLine1?: null;
  addressLine2?: null;
  city?: null;
  postalCode?: null;
  dataControllerFirstName?: null;
  dataControllerLastName?: null;
  dataControllerPhone?: null;
  dataControllerEmail?: null;
  corporateNumber?: null;
  taxNumber?: null;
  packagePlan?: null;
  signedDPA?: boolean;
  maxLocation?: number;
  primaryWebHost?: string;
  offline?: boolean;
  offlineMessage?: null;
  paymentMethodIntegrations?: PaymentMethodIntegration[];
  companyLocales?: Locale[];
  languageLocales?: Locale[];
  websites?: Website[];
  addressFieldRules?: AddressFieldRule[];
  companyIntegrations?: CompanyIntegration[];
  businessCategories?: BusinessCategory[];
  deliveryZoneSettings?: Setting[];
  workFlowSettings?: Setting[];
  companyOrderDiscountRules?: any[];
  companyPromoCodeDiscountRules?: any[];
  country?: Country;
  companyLocale?: Locale;
  companySetting?: CompanySetting;
  iosappURL?: string;
};
