export enum EventType {
  PRODUCT_ADDED = 'PRODUCT_ADDED',
  PROMO_CODE_APPLIED = 'PROMO_CODE_APPLIED',
  LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL',
  SIGNUP_SUCCESSFUL = 'SIGNUP_SUCCESSFUL',
  PROFILE_UPDATED = 'PROFILE_UPDATED',
  STORE_SEARCH = 'STORE_SEARCH',
  STORE_FILTERING = 'STORE_FILTERING',
  STORE_FILTERING_BY_NAME = 'STORE_FILTERING_BY_NAME',
  STORE_SELECTED = 'STORE_SELECTED',
  PRODUCT_FILTERING_BY_NAME = 'PRODUCT_FILTERING_BY_NAME',
  CHARGED = 'Charged',
  CHECKOUT_INITIATED = 'CHECKOUT_INITIATED',
  PAYMENT_INFO_ADDED = 'PAYMENT_INFO_ADDED',
  LOGGED_OUT = 'LOGGED_OUT',
  CART_VIEWED = 'CART_VIEWED'
}

export type EventInit = {
  cleverTapAccountId?: string;
};
