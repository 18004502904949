import { PaymentMethod } from './payment';
import { OrderDiscountRule } from './order';
import { Service } from './service';
import { PromoCodeDiscountRule } from './promoCode';
import { LocationMeta } from './location';
import { BusinessSubCategory } from './businessSubCategory';

export type BranchListingQueryParams = {
  orderType?: string;
  longitude?: string | number | null;
  latitude?: string | number | null;
  offset?: number;
  limit?: number;
  businessCategoryID?: number;
  businessSubCategoryID?: number;
};

export type Branch = {
  id: number;
  name: string;
  urlPath: string;
  phone: string;
  fax: string;
  postCode: string;
  addressLine1: string;
  addressLine2: string;
  town: string;
  city: string;
  county: string;
  country: string;
  tradingName?: any;
  registrationDate?: any;
  dataControllerFirstName?: any;
  dataControllerLastName?: any;
  dataControllerPhone?: any;
  dataControllerEmail?: any;
  corporateNumber?: any;
  taxNumber?: any;
  signedDPA: boolean;
  locationWebLogoUrl: string;
  emailFrom: string;
  emailOrder: string;
  emailClient1: string;
  emailClient2: string;
  emailClient3: string;
  emailClientCC?: any;
  smsClient?: any;
  orderConfirmation: boolean;
  enableReviews: boolean;
  clientSendSms: boolean;
  clientSendMail: boolean;
  clientSendFax: boolean;
  clientPhoneNotify: boolean;
  encryptPassword: boolean;
  automaticPrinting: boolean;
  timeZone: string;
  score: number;
  active: boolean;
  archive: boolean;
  companyId: number;
  latitude: string;
  longitude: string;
  orderConfirmationSetting: string;
  orderTracking: boolean;
  outsourcedDelivery: boolean;
  hasReviews: boolean;
  hasPreOrdering: boolean;
  hasDriverToStoreNotificationStatus: boolean;
  offline: boolean;
  foodHygieneRating?: any;
  paymentMethods: PaymentMethod[];
  services: Service[];
  taxes?: any;
  dayOpeningTimes?: any;
  dateOpeningTimes?: any;
  orderDiscountRules: OrderDiscountRule[];
  promoCodeDiscountRules: PromoCodeDiscountRule[];
  deliveryZones?: any;
  businessSubCategories: BusinessSubCategory[];
  reviews?: any;
  branchIntegrations?: any;
  locationMetaDatas: LocationMeta[];
  aboutSections?: any;
  infoSections?: any;
};

export type BranchListItem = {
  branch: Branch;
  isOpen: boolean;
  hasPreOrdering: boolean;
  isClosed: boolean;
  isOffline: boolean;
  open: boolean;
};
