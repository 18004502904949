import axios from 'axios';
import { UserContext } from '~src/types';
import { axiosInstance } from '~src/helpers';

let userContextResponse: UserContext;
export const getUserContext = async (loadFromCache = true): Promise<UserContext> => {
  if (userContextResponse && loadFromCache) {
    return userContextResponse;
  }
  const { data } = await axiosInstance.get('/context');
  userContextResponse = data;
  return userContextResponse;
};
