export type StorePageParams = {
  orderType?: string;
  longitude?: string | number;
  latitude?: string | number;
  location?: string;
  offset?: number;
  limit?: number;
  name?: string;
  businessCategoryID?: number | string;
  businessSubCategoryID?: number | string;
};
