import { events } from '~src/events';
import { getUserContext } from '~src/api';
import { initCleverTap } from '~src/helpers';
import { EventInit } from '~src/types';
import { eventEmitter } from '~src/helpers/EventEmitter';
import EventEmitter from 'eventemitter3';

let isInitialised = false;

async function dispatch(eventName: string, params: Record<string, any> = {}) {
  console.log(`dispatch: ${eventName}, ${JSON.stringify(params)}`);
  const result = await (isInitialised && events[eventName]
    ? events[eventName](params)
    : Promise.resolve());
  eventEmitter.emit(eventName, { params, result });
}

async function init(settings: EventInit) {
  if (isInitialised) {
    return;
  }
  if (settings.cleverTapAccountId) {
    await getUserContext(true);
    await initCleverTap(settings);
    isInitialised = true;
  }
  eventEmitter.emit('onInit');
}

(window as any).plastoCartEventEmitter = {
  dispatch,
  init,
  onInit: (func: EventEmitter.EventListener<'onInit', any>) => eventEmitter.on('onInit', func)
};
export default {
  dispatchEvent,
  init,
  listenToEvent: eventEmitter.on,
  eventEmitter
};
