export type PromoCodeDiscountRule = {
  id: number;
  branchId: number;
  createdDate?: any;
  expiryDate: string;
  orderType: string;
  paymentType: string;
  chargeMode: number;
  subTotal: number;
  discount: number;
  promoCode: string;
  useOnceOnly: boolean;
  active: boolean;
  archive: boolean;
};
