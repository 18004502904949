import { getUserContext, getBranch } from '~src/api';
import { EventType } from '~src/types';
import { getCommonEventPayload } from '~src/events/common';
import { pushEvent } from '~src/helpers';

export const storeSearchEvent = async (params: Record<string, string | number>) => {
  const userContext = await getUserContext();
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.STORE_SEARCH, {
    ...commonEventPayload,
    Search_Keyword: params.postCode,
    User_Logged_In: !!userContext?.customer
  });
};

export const storeFilterByNameEvent = async (params: Record<string, string | number>) => {
  const url = params.url;
  const urlParams = new URLSearchParams(url as string);
  const count = params.count || 0;
  const userContext = await getUserContext();
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.STORE_FILTERING_BY_NAME, {
    ...commonEventPayload,
    Search_Keyword: urlParams.get('name'),
    Count_Of_Stores: count,
    User_Logged_In: !!userContext?.customer
  });
};

export const storeFilteringEvent = async (params: Record<string, string | number>) => {
  const url = params.url;
  console.log(url);
  const urlParams = new URLSearchParams(url as string);
  const count = params.count || 0;
  const businessSubCategoryID = (urlParams.get('businessSubCategoryID') || '').split(',');

  const userContext = await getUserContext();
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.STORE_FILTERING, {
    ...commonEventPayload,
    // Business_Types: businessSubCategoryID,
    Business_Types: null,
    Search_Keyword: urlParams.get('name'),
    Count_Of_Stores: count,
    User_Logged_In: !!userContext?.customer
  });
};

export const storeSelectedEvent = async (params: Record<string, string | number>) => {
  const branchId = parseInt(params?.branchId as string, 10);
  const branch = await getBranch(branchId);
  const userContext = await getUserContext();
  const commonEventPayload = await getCommonEventPayload();
  // pushEvent(EventType.STORE_SELECTED, {
  //   Res_Image_Url: '',
  //   Company_ID: 192,
  //   Rating: 4.33,
  //   Device_Type: 'ANDROID_ORDERING',
  //   Country_ID: 'GB',
  //   Store_Name: 'Test Store 1',
  //   User_Logged_In: true,
  //   Business_Types: [
  //     {
  //       businessCategoryId: 43,
  //       iconUrl: '',
  //       id: 310,
  //       isActive: false,
  //       name: 'Chinese'
  //     },
  //     {
  //       businessCategoryId: 43,
  //       iconUrl: '',
  //       id: 311,
  //       isActive: false,
  //       name: 'Thai'
  //     }
  //   ],
  //   Store_Open: 'Open',
  //   Store_ID: 1517
  // });

  pushEvent(EventType.STORE_SELECTED, {
    ...commonEventPayload,
    Res_Image_Url: branch.locationWebLogoUrl,
    Store_ID: branch.id,
    Store_Name: branch.name,
    // Business_Types: branch.businessSubCategories?.map((branchSubCategory) => ({
    //   id: branchSubCategory.id,
    //   iconUrl: branchSubCategory.iconUrl,
    //   name: branchSubCategory.name,
    //   isActive: branchSubCategory.active,
    //   businessCategoryId: branchSubCategory.businessCategoryId
    // })),
    Business_Types: null,
    Rating:
      parseFloat(
        branch.locationMetaDatas?.find(
          (locationMetaData) => locationMetaData.paramName === 'averageReviewScore'
        )?.paramValue as string
      ) || 0,
    Store_Open: branch.locationMetaDatas?.find(
      (locationMetaData) => locationMetaData.paramName === 'branchStatus'
    )?.paramValue,
    User_Logged_In: !!userContext?.customer
  });
};
