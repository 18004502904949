import { Product } from './product';

export enum OrderType {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY'
}

export type OrderDiscountRule = {
  id: number;
  branchId: number;
  createdDate?: any;
  expiryDate: string;
  orderType: string;
  paymentType: string;
  chargeMode: number;
  subTotal: number;
  discount: number;
  useOnceOnly: boolean;
  active: boolean;
  archive: boolean;
};

export type OrderItem = {
  id?: null;
  orderItemId?: string;
  forWho?: null;
  instructions?: string;
  quantity?: number;
  purchaseSubTotal?: number;
  product?: Product;
  orderItemOptions?: any[];
};
