import clevertap from 'clevertap-web-sdk';
import $ from 'jquery';
import { EventInit } from '~src/types';
import { getUserCommonPayload } from '~src/events/common';

export const initCleverTap = ({ cleverTapAccountId }: EventInit) => {
  clevertap.init(cleverTapAccountId as string);
  clevertap.enablePersonalization = true;

  clevertap.privacy.push({ optOut: false }); //set the flag to true, if the user of the device opts out of sharing their data
  clevertap.privacy.push({ useIP: false }); //set the flag to true, if the user agrees to share their IP data
  clevertap.setOffline(false);
  clevertap.setLogLevel(3);
  addNotificationOverlay();
};
export const onUserLogin = (params: Record<string, any> = {}) => {
  console.log(JSON.stringify({ type: 'cleverTap:onUserLogin', ...params }));
  return clevertap.onUserLogin.push(params);
};

export const pushEvent = (eventName: string, params: Record<string, any> = {}) => {
  console.log(JSON.stringify({ type: `cleverTap:${eventName}`, ...params }));
  return clevertap.event.push(eventName, params);
};

export const addNotificationOverlay = () => {
  clevertap.notificationCallback = function (msg) {
    console.log(`cleverTap notificationCallback ${msg ? JSON.stringify(msg) : ''}}`);
    clevertap.renderNotificationViewed({ msgId: msg.msgId });
    const $button = $('<button></button>');
    $button.click(async () => {
      console.log(`cleverTap notificationCallback button click ${msg ? JSON.stringify(msg) : ''}}`);
      const userDetails = await getUserCommonPayload({ 'MSG-push': true });
      onUserLogin(userDetails);
      clevertap.renderNotificationClicked({ msgId: msg.msgId });
    });
  };
};

export const pushNotification = () => {
  clevertap.notifications.push({
    titleText: 'Would you like to receive Push Notifications?',
    bodyText:
      'We promise to only send you relevant content and give you updates on your transactions',
    okButtonText: 'Sign me up!',
    rejectButtonText: 'No thanks',
    okButtonColor: '#f28046',
    hidePoweredByCT: true,
    serviceWorkerPath: '/static/clevertap/clevertap_sw.js'
  });
};
