import { getUserContext } from '~src/api';
import { DeviceType } from '~src/types';

export const getCommonEventPayload = async () => {
  const userContext = await getUserContext();
  return {
    Country_ID: userContext?.company?.companyLocale?.country,
    Company_ID: userContext?.company?.id,
    Device_Type: DeviceType.WEBSITE_ORDERING
  };
};

export const getUserCommonPayload = async (options: Record<string, any> = {}) => {
  const userContext = await getUserContext(false);
  const customer = userContext.customer;
  return {
    Site: {
      Name: `${customer?.firstName} ${customer?.lastName}`,
      Identity: customer?.id,
      Email: customer?.email,
      Phone: customer?.phone?.replace(' ', ''),
      'MSG-email': customer?.promotionEmail,
      'MSG-sms': customer?.promotionSms,
      'MSG-whatsapp': true,
      RegistrationStartDate: customer?.registrationDate,
      PreferredLanguage: customer?.language,
      Country: customer?.country,
      UserType: customer?.customerType,

      City: userContext?.branch?.city,
      LastTransactionDate: userContext?.customerOrder?.orderDate,
      LastPurchasedProduct: userContext?.customerOrder?.orderItems?.[0]?.product?.name,
      LastPurchasedStore: userContext?.customerOrder?.location,
      ...options
    }
  };
};
