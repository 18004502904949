export function extractFromPath(pattern: string, url: string) {
  let keys: string[] = [],
    result: Record<string, any> = {},
    i;

  url = url || window.location.pathname;

  pattern = pattern.replace(/{([^}]+)}/g, function ($0, $1) {
    keys.push($1);
    return '([^/]+)';
  });

  const patternArray = url.match(new RegExp('^' + pattern));

  for (i = 0; i < keys.length; i++) {
    result[keys[i]] = patternArray?.[i + 1];
  }

  return result;
}
