import { AddressField } from './address';
import { OrderItem } from '~src/types/order';

export type CustomerOrder = {
  id?: null;
  customerType?: string;
  transId?: string;
  ipAddress?: string;
  orderDate?: Date;
  specialInstructions?: string;
  location?: null;
  customerPhone?: string;
  customerFirstName?: string;
  customerLastName?: string;
  orderStatus?: string;
  billingStatus?: null;
  printingStatus?: null;
  creditStatus?: null;
  phoneNotify?: boolean;
  sendFax?: boolean;
  sendSms?: boolean;
  preOrdered?: boolean;
  driverToStoreNotificationStatus?: null;
  orderType?: string;
  paymentType?: string;
  currencyCode?: null;
  orderTime?: string;
  promoCode?: string;
  sitePreference?: null;
  paymentGateway?: null;
  paymentGatewayReference?: null;
  orderConfirmationStatus?: null;
  orderConfirmationStatusMessage?: null;
  selfIsolation?: boolean;
  deliveryCharge?: number;
  surCharge?: number;
  driverTip?: null;
  amount?: number;
  subTotal?: number;
  orderDiscount?: number;
  promoCodeDiscount?: number;
  vipDiscount?: number;
  orderCredit?: null;
  review?: null;
  customerOrderAddress?: CustomerOrderAddress;
  customerOrderTaxes?: any[];
  customerId?: number;
  branchId?: number;
  firstCustomerOrder?: boolean;
  deliveryJob?: null;
  orderItems?: OrderItem[];
};

export type CustomerOrderAddress = {
  id?: number;
  customerOrderAddressFields?: AddressField[];
  customerOrderId?: null;
};
