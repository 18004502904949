export type PaymentMethodIntegrationParam = {
  id: number;
  key: string;
  value: string;
  paymentMethodIntegrationId: number;
};

export type PaymentMethodIntegration = {
  id: number;
  identifierName: string;
  processorName: string;
  companyId: number;
  paymentMethodIntegrationParams: PaymentMethodIntegrationParam[];
};

export type PaymentMethod = {
  id: number;
  orderType: string;
  status: string;
  paymentType: string;
  info: string;
  charge: number;
  minimumAmount: number;
  freeAmount: number;
  chargeMode: number;
  paymentForm: number;
  channel: number;
  branchIntegration?: any;
  paymentMethodIntegration: PaymentMethodIntegration;
  paymentMethodParams: any[];
  branchId: number;
};
