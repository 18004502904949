import { Company } from './company';
import { Website } from './website';
import { Customer } from './customer';
import { CustomerOrder } from './customerOrder';
import { Branch } from './branch';
import { ShoppingCart } from './shoppingCart';

export type UserContext = {
  defaultLocale?: string;
  currencyLocale?: string;
  company?: Company;
  branch?: Branch;
  customer?: Customer;
  customerOrder?: CustomerOrder;
  shoppingCart?: ShoppingCart;
  website?: Website;
};
