import { Product } from './product';

export type ShoppingCart = {
  branchId?: number;
  items?: ShoppingCartItem[];
  value?: number;
};

export type ShoppingCartItem = {
  id?: null;
  orderItemId?: string;
  forWho?: null;
  instructions?: string;
  quantity?: number;
  purchaseSubTotal?: number;
  product?: Product;
  orderItemOptions?: any[];
};
