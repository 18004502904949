import { Address } from './address';

export type Customer = {
  id?: number;
  customerType?: string;
  ipAddress?: string;
  internalInfo?: null;
  salutation?: null;
  phone?: string;
  mobile?: null;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  salt?: string;
  archive?: boolean;
  promotionSms?: boolean;
  promotionEmail?: boolean;
  receivePushNotification?: boolean;
  freeDelivery?: boolean;
  registrationDate?: Date;
  country?: string;
  language?: string;
  otpPhoneVerificationStatus?: string;
  companyId?: number;
  branchId?: number;
  addresses?: Address[];
  oneSignalCredentials?: any[];
};
