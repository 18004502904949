import { getBranch, getUserContext } from '~src/api';
import { EventType } from '~src/types';
import { pushEvent, pushNotification, sum } from '~src/helpers';
import { getCommonEventPayload } from './common';
import { userUpdateAfterChargedEvent } from './user';

export const checkoutInitEvent = async () => {
  const userContext = await getUserContext(false);
  const branchId = userContext.shoppingCart?.branchId;
  const branch = await getBranch(branchId || 0);
  const commonEventPayload = await getCommonEventPayload();

  pushEvent(EventType.CHECKOUT_INITIATED, {
    ...commonEventPayload,
    Store_ID: branch?.id,
    Store_Name: branch?.name,
    Subtotal: userContext.shoppingCart?.value
  });
};

export const promoCodeAppliedEvent = async () => {
  const userContext = await getUserContext();
  const commonEventPayload = await getCommonEventPayload();
  const customerOrder = userContext.customerOrder;
  const branch = await getBranch(customerOrder?.branchId || 0);

  pushEvent(EventType.PROMO_CODE_APPLIED, {
    ...commonEventPayload,
    Promo_Code: customerOrder?.promoCode,
    Store_ID: branch?.id,
    Store_Name: branch?.name,
    Order_Time: customerOrder?.orderTime,
    Pre_Discount_Price: customerOrder?.amount,
    Total_Savings: sum([
      customerOrder?.promoCodeDiscount,
      customerOrder?.orderDiscount,
      customerOrder?.orderCredit,
      customerOrder?.vipDiscount
    ]),
    Promo_Code_Applied: !!customerOrder?.promoCode,
    Promo_Code_Discount_Amount: customerOrder?.promoCodeDiscount,
    Payment_Method: customerOrder?.paymentType,
    Grand_Total: customerOrder?.subTotal,
    Customer_Order_ID: customerOrder?.id
  });
};

export const cartViewEvent = async () => {
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.CART_VIEWED, {
    ...commonEventPayload
  });
};

export const paymentInfoAddedEvent = async () => {
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.PAYMENT_INFO_ADDED, {
    ...commonEventPayload
  });
};

export const checkoutCompletedEvent = async () => {
  const userContext = await getUserContext();

  const customerOrder = userContext.customerOrder;
  if (!customerOrder || !userContext?.shoppingCart?.items?.length) {
    return;
  }
  const commonEventPayload = await getCommonEventPayload();
  const branch = await getBranch(customerOrder?.branchId || 0);
  if (!!customerOrder?.promoCode) {
    await promoCodeAppliedEvent();
  }
  await userUpdateAfterChargedEvent();
  pushEvent(EventType.CHARGED, {
    ...commonEventPayload,
    Driver_Tip: Number(customerOrder?.driverTip),
    Order_Time: customerOrder?.orderTime || '',
    Amount: Number(customerOrder?.subTotal),
    Subtotal: Number(customerOrder?.amount),
    Promo_Code_Discount_Amount: Number(customerOrder?.promoCodeDiscount),
    Payment_Method: customerOrder?.paymentType || '',
    Store_ID: Number(branch?.id),
    Customer_Order_ID: Number(customerOrder?.id),
    Card_Surcharge: Number(customerOrder?.surCharge),
    First_Customer_Order: customerOrder?.firstCustomerOrder,
    Order_Type: customerOrder?.orderType || '',
    Order_Discount_Amount: Number(customerOrder?.orderDiscount),
    Currency: userContext.currencyLocale || '',
    Delivery_Charge: Number(customerOrder?.deliveryCharge),
    Store_Name: branch?.name || '',
    Total_Savings: sum([
      customerOrder?.promoCodeDiscount,
      customerOrder?.orderDiscount,
      customerOrder?.orderCredit,
      customerOrder?.vipDiscount
    ]),
    Promo_Code: customerOrder?.promoCode || '',
    Taxes: null,
    Items: userContext?.shoppingCart?.items?.map((cartItem) => ({
      Category_ID: Number(cartItem.product?.categoryId),
      Product_ID: Number(cartItem.product?.id),
      Product_Name: cartItem.product?.name || '',
      Quantity: Number(cartItem.quantity),
      Price: Number(cartItem.purchaseSubTotal)
    }))
  });
  pushNotification();
};
