import { getUserContext } from '~src/api';
import { EventParam, EventType, ParamFormData } from '~src/types';
import { getCommonEventPayload, getUserCommonPayload } from '~src/events/common';
import { onUserLogin, pushEvent, pushNotification } from '~src/helpers';

export const userLoginEvent = async () => {
  const payload = await getUserCommonPayload();
  onUserLogin({ ...payload });
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.LOGIN_SUCCESSFUL, { ...commonEventPayload });
};

export const userRegisterEvent = async () => {
  const payload = await getUserCommonPayload();
  delete payload.Site.City;
  delete payload.Site.LastTransactionDate;
  delete payload.Site.LastPurchasedStore;
  onUserLogin({ ...payload });
  const userContext = await getUserContext();
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.SIGNUP_SUCCESSFUL, {
    ...commonEventPayload,
    Signup_Date: userContext?.customer?.registrationDate
  });
};

export const profileUpdateEvent = async (params: EventParam) => {
  const formData = params.formData as ParamFormData;
  const userContext = await getUserContext();

  const previousValueObject: Record<string, string | undefined> = {
    companyId: String(userContext.company?.id),
    firstName: userContext.customer?.firstName,
    lastName: userContext.customer?.lastName,
    email: userContext.customer?.email,
    phone: userContext.customer?.phone
  };

  const commonEventPayload = await getCommonEventPayload();
  formData.forEach((formItem) => {
    const key = formItem.name;
    if (previousValueObject[key] !== formItem.value) {
      pushEvent(EventType.PROFILE_UPDATED, {
        updatedField: key,
        Previous_Value: previousValueObject[key],
        Updated_Value: formItem.value,
        ...commonEventPayload
      });
    }
  });
};

export const profileUpdatePasswordEvent = async () => {
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.PROFILE_UPDATED, {
    updatedField: 'password',
    Previous_Value: '',
    Updated_Value: '',
    ...commonEventPayload
  });
};

export const userLogout = async () => {
  const commonEventPayload = await getCommonEventPayload();
  pushEvent(EventType.LOGGED_OUT, {
    ...commonEventPayload
  });
};

export const userUpdateAfterChargedEvent = async () => {
  const payload = await getUserCommonPayload();
  onUserLogin({ ...payload });
};
