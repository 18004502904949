export type Website = {
  id: number;
  scheme?: string;
  standardWebsiteURL?: string;
  mobileWebsiteURL?: null;
  standardFolderPath?: string;
  mobileFolderPath?: null;
  redirectIndexUrl?: null;
  rootStaticUrl?: string;
  redirectIfNotWWW?: boolean;
  companies?: any[];
  widget?: boolean;
};
